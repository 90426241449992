// import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { yellow } from '@material-ui/core/colors';
import { Images } from './Images'
import { IsEmpty, getItemStore, storageKeys } from './appConstant';
let isAdmin = getItemStore(storageKeys.RoleName)
let TenantConfiguration = !IsEmpty(getItemStore(storageKeys.TenantConfiguration)) ? JSON.parse(getItemStore(storageKeys.TenantConfiguration)) : ''

const BauschReachThemeFontFamily = {
  FontFamily_1: isAdmin == 1 ? 'Nunito, sans-serif' : 'Barlow, sans-serif',
  FontFamily_2: isAdmin != 1 ? 'Barlow, sans-serif' : 'Nunito, sans-serif',
  FontFamily_3: 'Barlow, sans-serif',
};

const MosquitoNixThemeFontFamily = {
  FontFamily_1: 'Myriad Pro, Open Sans',
  FontFamily_2: 'Myriad Pro, Open Sans',
  FontFamily_3: 'Myriad Pro, Open Sans',
}

const BauschReachTheme = {
  mainColor: '#367e88',
  maintablecolor: '#114d8b',
  blackColor: '#000',
  lightBlackColor: '#000000d1',
  grayColor: "#6d6e71",
  dividerColor: "#BDBDBD",
  ThirdButtonColor: '#0070e4',
  ThirdButtonHoverColor: '#1073c6',
  SecoundButtonColor: '#225151',
  SecoundButtonHoverColor: '#225151',
  fontgreendark: '#173a40',
  mainButtonColor: '#16416f',
  mainButtonHoverColor: '#16416f',
  bluetext: '#0F568C',
  navLinkColor: '#6d6e71',
  navLinkHoverColor: "#1073c6",
  navDropDownOptionHoverBg: '#f5f7fa',
  navDropDownborder: '#f5f7fa',
  headersbackgroundcolor: "#FFFFFF",
  loginhed2color: "#16416f",
  hometilesubtext: "#000000",
  hometilemenhead: "#000000",

  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayblackColor: "#6d6e71",
  BlackTextColor: "#0f0f0f",
  fieldColor: "#F5F5F5",
  blueColor: "#5DADE2",
  blueLinkColor: "#043b41",
  grayLightColor: '#dfdfdf',
  greenColor: "#06b80a",
  accentColor: "#4A90A4",
  redColor: '#FF0000',
  light_blue_row: '#ADD8E6',
  inputMainColor: '#0070e4',
  inputBgColor: '#f5f7fa',
  inputBorderColor: "#d8dbe0",
  inputBorderHoverColor: "#86868b",
  inputErrorColor: '#f44336',
  inputFontColor: '#646464',
  inputFocusBorderColor: '#d8dbe0',
  inputLableColor: '#6d6d6d',
  formcontrolBtnColor: '#cdcfd7',
  closeBtnColor: '#b73232',
  cardlightbgcolor: '#f4f4f7',
  cardDarkbgcolor: '#e8e8e8',
  emaillinkcolor: '#2157e1',
  darkgreen: '#043b41',
  garycardcolor: '#ececec',
  skycolor: '#0079c2',
  loaderColor: '#0a5c7f',
  fbIconColor: "#1877F2",
  instaIconColor: '#a43c94',
  paypalIconColor: '#0079C1',
  greenIconColor: '#3c763d',
  redIconColor: '#a94442',
  lightpurplecolor: '#4a5f8e',
  Custom_Family: BauschReachThemeFontFamily,

}
const USSMO = {
  mainColor: '#367e88',
  maintablecolor: '#114d8b',
  blackColor: '#000',
  lightBlackColor: '#000000d1',
  grayColor: "#6d6e71",
  dividerColor: "#BDBDBD",
  ThirdButtonColor: '#0070e4',
  ThirdButtonHoverColor: '#1073c6',
  SecoundButtonColor: '#225151',
  SecoundButtonHoverColor: '#225151',
  fontgreendark: '#173a40',
  mainButtonColor: '#16416f',
  mainButtonHoverColor: '#16416f',
  bluetext: '#0F568C',
  navLinkColor: '#6d6e71',
  navLinkHoverColor: "#1073c6",
  navDropDownOptionHoverBg: '#f5f7fa',
  navDropDownborder: '#f5f7fa',
  headersbackgroundcolor: "#FFFFFF",
  loginhed2color: "#16416f",
  hometilesubtext: "#000000",
  hometilemenhead: "#000000",

  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayblackColor: "#6d6e71",
  BlackTextColor: "#0f0f0f",
  fieldColor: "#F5F5F5",
  blueColor: "#5DADE2",
  blueLinkColor: "#043b41",
  grayLightColor: '#dfdfdf',
  greenColor: "#06b80a",
  accentColor: "#4A90A4",
  redColor: '#FF0000',
  light_blue_row: '#ADD8E6',
  inputMainColor: '#0070e4',
  inputBgColor: '#f5f7fa',
  inputBorderColor: "#d8dbe0",
  inputBorderHoverColor: "#86868b",
  inputErrorColor: '#f44336',
  inputFontColor: '#646464',
  inputFocusBorderColor: '#d8dbe0',
  inputLableColor: '#6d6d6d',
  formcontrolBtnColor: '#cdcfd7',
  closeBtnColor: '#b73232',
  cardlightbgcolor: '#f4f4f7',
  cardDarkbgcolor: '#e8e8e8',
  emaillinkcolor: '#2157e1',
  darkgreen: '#043b41',
  garycardcolor: '#ececec',
  skycolor: '#0079c2',
  loaderColor: '#0a5c7f',
  fbIconColor: "#1877F2",
  instaIconColor: '#a43c94',
  paypalIconColor: '#0079C1',
  greenIconColor: '#3c763d',
  redIconColor: '#a94442',
  lightpurplecolor: '#4a5f8e',
  Custom_Family: BauschReachThemeFontFamily,
}

const MosquitoNix = {
  yellowColor: "#FFC72C",
  mainColor: '#367e88',
  maintablecolor: '#114d8b',
  blackColor: '#000',
  lightBlackColor: '#000000d1',
  grayColor: "#6d6e71",
  dividerColor: "#000000",
  ThirdButtonColor: '#007934',
  ThirdButtonHoverColor: '#007934',
  SecoundButtonColor: '#007934',
  SecoundButtonHoverColor: '#007934',
  fontgreendark: '#173a40',
  mainButtonColor: '#007934',
  mainButtonHoverColor: '#007934',
  bluetext: '#0F568C',
  navLinkColor: '#000000',
  navLinkHoverColor: "#007934",
  navDropDownOptionHoverBg: '#f5f7fa',
  navDropDownborder: '#f5f7fa',
  headersbackgroundcolor: "#FFC72C",
  loginhed2color: "#000000",
  hometilesubtext: "#FFC72C",
  hometilemenhead: "#FFFFFF",

  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayblackColor: "#000000",
  BlackTextColor: "#0f0f0f",
  fieldColor: "#F5F5F5",
  blueColor: "#5DADE2",
  blueLinkColor: "#000000",
  grayLightColor: '#dfdfdf',
  greenColor: "#06b80a",
  accentColor: "#4A90A4",
  redColor: '#FF0000',
  light_blue_row: '#ADD8E6',
  inputMainColor: '#000000',
  inputBgColor: '#f5f7fa',
  inputBorderColor: "#d8dbe0",
  inputBorderHoverColor: "#86868b",
  inputErrorColor: '#f44336',
  inputFontColor: '#646464',
  inputFocusBorderColor: '#d8dbe0',
  inputLableColor: '#6d6d6d',
  formcontrolBtnColor: '#cdcfd7',
  closeBtnColor: '#b73232',
  cardlightbgcolor: '#f4f4f7',
  cardDarkbgcolor: '#e8e8e8',
  emaillinkcolor: '#2157e1',
  darkgreen: '#043b41',
  garycardcolor: '#ececec',
  skycolor: '#0079c2',
  loaderColor: '#007934',
  fbIconColor: "#1877F2",
  instaIconColor: '#a43c94',
  paypalIconColor: '#0079C1',
  greenIconColor: '#3c763d',
  redIconColor: '#a94442',
  lightpurplecolor: '#4a5f8e',
  Custom_Family: MosquitoNixThemeFontFamily,
}

const DefaultTheme = {
  mainColor: '#367e88',
  main: '#114d8b',
  blackColor: '#000',
  lightBlackColor: '#000000d1',
  grayColor: "#6d6e71",
  dividerColor: "#BDBDBD",
  ThirdButtonColor: '#0070e4',
  ThirdButtonHoverColor: '#1073c6',
  SecoundButtonColor: '#225151',
  SecoundButtonHoverColor: '#225151',
  fontgreendark: '#173a40',
  mainButtonColor: '#16416f',
  mainButtonHoverColor: '#16416f',
  bluetext: '#0F568C',
  navLinkColor: '#6d6e71',
  navLinkHoverColor: "#1073c6",
  navDropDownOptionHoverBg: '#f5f7fa',
  navDropDownborder: '#f5f7fa',

  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  grayblackColor: "#6d6e71",
  BlackTextColor: "#0f0f0f",
  fieldColor: "#F5F5F5",
  blueColor: "#5DADE2",
  blueLinkColor: "#043b41",
  grayLightColor: '#dfdfdf',
  greenColor: "#06b80a",
  accentColor: "#4A90A4",
  redColor: '#FF0000',
  light_blue_row: '#ADD8E6',
  inputMainColor: '#0070e4',
  inputBgColor: '#f5f7fa',
  inputBorderColor: "#d8dbe0",
  inputBorderHoverColor: "#86868b",
  inputErrorColor: '#f44336',
  inputFontColor: '#646464',
  inputFocusBorderColor: '#d8dbe0',
  inputLableColor: '#6d6d6d',
  formcontrolBtnColor: '#cdcfd7',
  closeBtnColor: '#b73232',
  cardlightbgcolor: '#f4f4f7',
  cardDarkbgcolor: '#e8e8e8',
  emaillinkcolor: '#2157e1',
  darkgreen: '#043b41',
  garycardcolor: '#ececec',
  skycolor: '#0079c2',
  loaderColor: '#0a5c7f',
  fbIconColor: "#1877F2",
  instaIconColor: '#a43c94',
  paypalIconColor: '#0079C1',
  greenIconColor: '#3c763d',
  redIconColor: '#a94442',
  lightpurplecolor: '#4a5f8e',
  Custom_Family: BauschReachThemeFontFamily,



}
export const graphColors = ["#02ABAE", "#1588ba", "#486e6e", "#0000ff", "#0a5c7f", "#008080", "#ec9455", "#c377e4", "#6EC496", "#1073C6", "#5a6e7f", "#646464", "#669999"];
export const graphColorsRGB = ["rgb(2, 171, 174,0.5)", "rgb(21, 136, 186,0.5)", "rgb(72, 110, 110,0.5)", "rgb(0, 0, 255,0.5)", "rgb(10, 92, 127,0.5)", "rgb(0, 128, 128,0.5)", "rgb(236, 148, 85,0.5)", "rgb(195, 119, 228,0.5)", "rgb(110, 196, 150, 0.5)", "rgb(16, 115, 198, 0.5)", "rgb(90, 110, 127, 0.5)", "rgb(100, 100, 100, 0.5)", "rgb(102, 153, 153,0.5)"];

export const GetTheme = () => {
  if (TenantConfiguration[0]?.t == undefined) {
    const HostName = window.location.hostname;
    switch (true) {
      case HostName.includes("bl.console.mybauschreach.com"):
        return BauschReachTheme;
      case HostName.includes("bl.console.thestaging.cc"):
        return BauschReachTheme;
      case HostName.includes("oralsurgeryconnect.com"):
        return USSMO;
      case HostName.includes("usosm.console.thestaging.cc"):
        return USSMO;
      case HostName.includes("mosquitonixconnect.com"):
        return MosquitoNix;
      default:
        return MosquitoNix;
    }
  } else {
    switch (TenantConfiguration[0]?.t) {
      case 'Bausch Reach':
        return BauschReachTheme
      case 'USOSM':
        return USSMO
      case 'MOSQUITONIX':
        return MosquitoNix
      default:
        return DefaultTheme
    }
  }
};

export const BaseColor = GetTheme()
export const ThemeName = TenantConfiguration[0]?.t
export const FontFamily = GetTheme().Custom_Family

export const BaseImages = {
  footerImage: `url(${!IsEmpty(TenantConfiguration[0]) ? TenantConfiguration[0].r : Images.FooterBG})`,
  nextImage: `url(${Images.tileButtonRightHover})`,
  nextImageRollover: `url(${Images.tileButtonRightRollover})`,
  preImage: `url(${Images.tileButtonLeftHover})`,
  preImageRollover: `url(${Images.tileButtonLeftRollover})`,
  loginImgBg: `url(${!IsEmpty(TenantConfiguration[0]) ? TenantConfiguration[0].m : ''})`,
}

